import React, { useEffect, useState } from 'react'
import Navbar from './Component/Navbar'
import { useNavigate } from 'react-router-dom';
import Footer from './Component/Footer';
import { getFirestore, collection, getDocs, getDoc, doc, query, orderBy } from 'firebase/firestore';
import { Helmet } from 'react-helmet';

function Categories() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const fetchCategories = async () => {
    try {
      const firestore = getFirestore();
      const categoriesCollection = collection(firestore, 'Categories');
      const categoriesQuery = query(categoriesCollection, orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(categoriesQuery);
      const categoriesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesList);
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoadingCategories(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleCategoryClick = (category) => {
    console.log(category)
    navigate(`/categories/${category.createCategories}`, { state: category.stores });
    // alert(JSON.stringify(category.stores));
  };
  
  return (
    <div>
      <Helmet>
        <title>Categories - Getgutschein</title>
        <meta name='description' content='Genießen Sie die besten Angebote und Verkäufe mit saisonalen Gutscheinen und Rabattcodes. Viele nicht saisonale Rabattangebote'/>
      </Helmet>
      <Navbar />
      <div>
          <div style={{
            fontFamily: "Poppins",
            fontSize: 20,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            paddingTop: "2%",
            paddingBottom: "2%"
          }}>
            Top Kategorien
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            {loadingCategories ? (
              <p>Loading...</p>
            ) : (
              categories.map((category) => (
                <div
                  key={category.id}
                  className="card m-3"
                  style={{ width: '18rem', cursor: 'pointer' }}
                  onClick={() => handleCategoryClick(category)}
                >
                  <div className="card-body d-flex justify-content-center align-items-center">
                    <h5 className="card-title">{category.createCategories || 'No Title'}</h5>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      
      <Footer/>
      </div>

  )
}

export default Categories