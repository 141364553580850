import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import axios from 'axios';
import { baseUrl } from '../../BaseUrl';

function Navbar() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [selectedStoreId, setSelectedStoreId] = useState(null);

  // Toggle modal visibility
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // Fetch store data from the API
  const getDataFromDB = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/createStore`);
      const result = response.data;

      // Sort data by create_time in descending order
      const sortedData = result.sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      setStores(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    getDataFromDB();
  }, []);

  // Filter stores based on the search term
  useEffect(() => {
    const results = stores.filter(store =>
      store.storeName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredStores(results);
  }, [searchTerm, stores]);

  // Handle store item click
  // const handleStoreClick = (store) => {
  //   // console.log("this is navbar",store.storeName)
  //   // return
  //   // setSearchTerm("");
  //   navigate(`/OpenStore/${store.id}/${store.storeName}`,{ state: store});
  //   setSelectedStoreId(store.id);
  // };

  const handleStoreClick = (storeId,storename,store) => {
    setSearchTerm("");
    navigate(`/OpenStore/${storeId}/${storename}`, { state: store });
    setSelectedStoreId(storeId);
  };
  return (
    <div>
      <div style={{ height: '130px' }}>
        <div className='hero-section' style={{
          backgroundColor:"#c4ece4",
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat",
          backgroundPosition: 'center',
          height: "100%",
          width: "100%",
          overflow: 'hidden',
          borderBottom: '1px solid #3E3E3F'
        }}>
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link to={'/'} className="navbar-brand" style={{ fontFamily: "Poppins", fontSize: "2.3rem", textDecoration: "none", color: "black", fontWeight: "bolder" }}>
                <img src='/newlogo.png' alt='Getgutschein' style={{ maxWidth: "100%", height: "60px", borderRadius: 10 }} />
              </Link>
              <div>
                <form className="form-inline w-100 justify-content-center">
                  <div className="input-group w-150">
                    <input
                      className="form-control search-input"
                      style={{ border: "0.7px solid green", borderRadius: "10px", width: "100%" }}
                      type="search"
                      placeholder="Search By Shop"
                      aria-label="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <button className="navbar-toggler" type="button" onClick={toggleModal}>
                <span className="navbar-toggler-icon"></span>
              </button>
             
              <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to={'/TopCoupon'} className="nav-link" style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                      <img src='/gift-voucher.png' alt='store' style={{ width: 'auto', height: '1.9rem', marginRight: "5px" }} />
                      Top Gutschein
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={'/shop'} className="nav-link" style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                      <img src='/store.png' alt='store' style={{ width: 'auto', height: '1.8rem', marginRight: "5px" }} />
                      Alle shops
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={'/categories'} className="nav-link" style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                      <img src='/cate.png' alt='categories' style={{ width: 'auto', height: '1.8rem', marginRight: "5px" }} />
                      Kategorien
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={'/Blog'} className="nav-link" style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                      <img src='/blog.png' alt='blog' style={{ width: 'auto', height: '1.8rem', marginRight: "5px" }} />
                      Blogs
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>

      {searchTerm && (
        <div className="search-results">
          <ul className="list-group">
            {filteredStores.length > 0 ? (
              filteredStores.map(store => (
                <li
                  key={store.id}
                  className="list-group-item"
                  onClick={() => handleStoreClick(store.id,store.storeName,store)}
                  style={{ cursor: 'pointer' }}
                >
                  <img src={`${store.StoreImage}`} alt={store.storeName} style={{ width: 60, height: 30, paddingRight: 0 }} />
                  {store.storeName}
                </li>
              ))
            ) : (
              <li className="list-group-item">No stores found</li>
            )}
          </ul>
        </div>
      )}

      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to={'/TopCoupon'} className="nav-link" onClick={closeModal} style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                <img src='/gift-voucher.png' alt='store' style={{ width: 'auto', height: '1.9rem', marginRight: "5px" }} />
                Top Gutschein
              </Link>
            </li>
            <li className="nav-item">
              <Link to={'/shop'} className="nav-link" onClick={closeModal} style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                <img src='/store.png' alt='store' style={{ width: 'auto', height: '1.8rem', marginRight: "5px" }} />
                SHOP
              </Link>
            </li>
            <li className="nav-item">
              <Link to={'/categories'} className="nav-link" onClick={closeModal} style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                <img src='/cate.png' alt='categories' style={{ width: 'auto', height: '1.8rem', marginRight: "5px" }} />
                CATEGORIES
              </Link>
            </li>
            <li className="nav-item">
              <Link to={'/Blog'} className="nav-link" onClick={closeModal} style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                <img src='/blog.png' alt='blog' style={{ width: 'auto', height: '1.8rem', marginRight: "5px" }} />
                Blogs
              </Link>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Navbar;
