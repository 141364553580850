import React, { useEffect, useRef, useState } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import JoditEditor from 'jodit-react';
import { baseUrl } from '../../BaseUrl';

function CreateShop() {
  const navigate = useNavigate();

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [storeName, setStoreName] = useState('');
  const [storeLogo, setStoreLogo] = useState();
  const [storeUrl, setStoreUrl] = useState('');
  const [affiliatedStoreUrl, setAffiliatedStoreUrl] = useState('');
  const [storeTitle, setStoreTitle] = useState('');
  const [storeSecondTitle, setStoreSecondTitle] = useState('');
  const [storeDescription, setStoreDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetchAllStoreData, setFetchAllStoreData] = useState([]);
  const [loadingStores, setLoadingStores] = useState(true);
  const [selectedStore, setSelectedStore] = useState(null);
  const [metaLine,setMetaLine]=useState("")
  const [storeSEODes,setStoreSEODes]=useState('')
  const {storeID,setStoreID}=useState()

  const editor = useRef(null);
  const [content, setContent] = useState(storeDescription);

  const config = {
    readonly: false,
    toolbarSticky: false,
    language: 'en',
    buttons: [
      'source', '|', 'bold', 'italic', 'underline', 'strikethrough', '|',
      'superscript', 'subscript', '|', 'ul', 'ol', '|',
      'outdent', 'indent', '|', 'font', 'fontsize', 'brush', 'paragraph', '|',
      'image', 'video', 'table', 'link', '|', 'align', 'undo', 'redo', '\n',
      'hr', 'eraser', 'copyformat', '|', 'symbol', 'fullsize', 'print', 'about', '|',
      'cut', 'copy', 'paste', '|', 'selectall', 'preview', 'code'
    ],
    uploader: {
      insertImageAsBase64URI: true,
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
      process: function (files, editor) {
        files.forEach(file => {
          const reader = new FileReader();
          reader.onload = function (e) {
            editor.s.insertImage(e.target.result);
          };
          reader.readAsDataURL(file);
        });
      },
      error: function (e) {
        console.log('Error:', e);
      },
    },
    events: {
      // Apply responsive styles to inserted images
      afterInsertImage: (img) => {
        img.style.maxWidth = '100%';
        img.style.height = 'auto';
        img.classList.add('responsive-image'); // Optional class
      }
    }
  };


  const handleUpdate = async () => {
    setLoading(true);
  
    const formData = new FormData();
    formData.append("storeName", storeName);
    
    // Append either the new image or the existing one
   formData.append("StoreImage",storeLogo)
    formData.append("simpleStoreURL", storeUrl);
    formData.append("affiliatedStoreURL", affiliatedStoreUrl);
    formData.append("storeTittle", storeTitle);
    formData.append("storeSecondTittle", storeSecondTitle);
    formData.append("StoreDescription", content);
    formData.append("addMetaTaq", metaLine);
    formData.append("storeSEODescription", storeSEODes);
  
    try {
      await axios.put(`${baseUrl}api/createStore/${selectedStore.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      toast.success('Your Store Data Updated Successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  
      getDataFromDB();
      cleanForm();
      setLoading(false);
      setShowUpdateModal(false); // Close the modal
    } catch (error) {
      toast.error('Your Store Data is not Updated Successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(error);
      setLoading(false);
    }
  };
  
  

  const cleanForm = () => {
    setStoreName('');
    setStoreLogo();
    setStoreUrl('');
    setStoreTitle('');
    setStoreSecondTitle('');
    setStoreDescription('');
    setAffiliatedStoreUrl('');
    setMetaLine('');
    setStoreSEODes('');
  };


  const handleDelete = async (store) => {
    console.log(store.id)
    setLoading(true);
  
    try {
      await axios.delete(`${baseUrl}api/createStore/${store.id}`);
  
      toast.success('Your Store Data Deleted Successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  
      getDataFromDB(); // Refresh data
      // setShowUpdateModal(false); // Close the modal
    } catch (error) {
      console.error(error);
      toast.error('Your Store Data is not Deleted Successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  };
  const getDataFromDB = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/createStore`);

      // Assuming the server returns JSON data
      const result = response.data;
      // console.log(result)
      // Sort data by create_time in descending order
      // const sortedData = result.sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      // const sortedData = result.sort((a, b) => new Date(a.create_time) - new Date(b.create_time));
      const sortedData = result.sort((a, b) => new Date(b.createTime) - new Date(a.createTime));
      setFetchAllStoreData(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingStores(false); // Set loading to false once data is fetched
    }
  };

  useEffect(() => {
    getDataFromDB();
  }, []);

  const goaddstorepage = async (store) => {
    navigate('/admin/AddStore', { state: { data: store } });
  }
  const handleGridClick = (store) => {
    // alert(store.StoreDescription)
    // setStoreID(store.id);
    setSelectedStore(store);
    setStoreName(store.storeName);
    setStoreLogo(store.StoreImage);
    setStoreUrl(store.simpleStoreURL);
    setAffiliatedStoreUrl(store.affiliatedStoreURL);
    setStoreTitle(store.storeTittle);
    setMetaLine(store.addMetaTaq);
    setStoreSEODes(store.storeSEODescription);
    setStoreSecondTitle(store.storeSecondTittle);
    setContent(store.StoreDescription);
    setShowUpdateModal(true);
  };

  const closeupdatemodel=()=>{
    cleanForm()
    setShowUpdateModal(false);
  }
const addstore=async()=>{
  navigate(`/admin/AddStore`);
}
  return (
    <div style={{ height: '100vh', overflow: 'hidden', backgroundColor: "#f3f9f9", borderRadius: 10 }}>
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        autoHide
        autoHideTimeout={10}
        autoHideDuration={10}
        thumbMinSize={10}
        universal={true}
        renderThumbVertical={({ style, ...props }) =>
          <div {...props} style={{ ...style, backgroundColor: 'black', borderRadius: '4px' }} />
        }
      >
        <div className="d-flex justify-content-end align-items-end" style={{ padding: "20px" }}>
          <Button type="button" className="btn btn-primary" onClick={addstore}>
            Add Store
          </Button>
        </div>

        
{/* ============================update store model======================================= */}
        <Modal
          show={showUpdateModal}
          onHide={()=>closeupdatemodel()}
          scrollable
          size="lg"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title" style={{ color: "green" }}>
              Update Store
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "justify" }}>
            <Form>
              <Row>
                <Col>
                  <Form.Group controlId="formShopName">
                    <Form.Label>Store Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter shop name"
                      value={storeName}
                      onChange={(e) => setStoreName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formShopLogo">
                    <Form.Label>Shop Image</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => setStoreLogo(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formTitleCoupon">
                    <Form.Label>Simple Store URL</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter the Store URL"
                      value={storeUrl}
                      onChange={(e) => setStoreUrl(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formTitleCoupon">
                    <Form.Label> Affiliated Store URL</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter the Store URL"
                      value={affiliatedStoreUrl}
                      onChange={(e) => setAffiliatedStoreUrl(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formStoreTitle">
                    <Form.Label>Store Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Store Title"
                      value={storeTitle}
                      onChange={(e) => setStoreTitle(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formStoreSecondTitle">
                    <Form.Label>Store Second Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Store Second Title"
                      value={storeSecondTitle}
                      onChange={(e) => setStoreSecondTitle(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              
              <Row>
                <Col>
                  <Form.Group controlId="formStoreTitle">
                    <Form.Label style={{fontWeight:"bold"}}>Add meta Taq line</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter meta Taq line"
                      value={metaLine}
                      onChange={(e) => setMetaLine(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formStoreDescription">
                    <Form.Label style={{fontWeight:"bold"}}>Store SEO Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter Store SEO Description"
                      value={storeSEODes}
                      onChange={(e) => setStoreSEODes(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formStoreDescription">
                  <JoditEditor
        ref={editor}
        value={content}
        config={config}
        tabIndex={1}
        onBlur={newContent => setContent(newContent)} // Update state when the editor loses focus
        onChange={() => {}} // Keep this empty to prevent re-renders on every keystroke
      />
                  </Form.Group>
                </Col>
              </Row>
            
          
            </Form>
          </Modal.Body>
          <Modal.Footer>
          <Button
                variant="primary"
                onClick={handleUpdate}
                disabled={loading}
              >
                {loading ? 'Updating...' : 'Update'}
              </Button>
              <Button
                variant="danger"
                onClick={handleDelete}
                disabled={loading}
                style={{ marginLeft: '10px' }}
              >
                {loading ? 'Deleting...' : 'Delete'}
              </Button>
          </Modal.Footer>
        </Modal>

        {loadingStores ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%', backgroundColor: '#f3f9f9' }}>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <h2 className='ml-2' style={{ fontFamily: "fantasy" }}>All Stores</h2>
          <div className="row row-cols-1 row-cols-md-3 g-3">
            {fetchAllStoreData.map((store) => (
              <div
                className='col-12 col-md-4 mb-3'
                key={store.id}
                style={{ display: 'flex', justifyContent: 'center', cursor: "pointer" }}
                // onClick={() => goaddstorepage(store)}
              >
                <div style={{
                  width: "100%",
                  backgroundColor: "#ffffff",
                  height: "35vh",
                  borderRadius: 8,
                  border: '1px solid #c7c9c9',
                  margin: '0 10px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.7)' // Add shadow here
                }}>
                  <div style={{
                    width: "100%",
                    height: '25%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderBottom: '1px solid #c7c9c9'
                  }}>
                    <img src={store.StoreImage} alt={store.storeName} style={{ width: 90, height: 37 }} />
                  </div>
                  <div style={{
                    width: "100%",
                    height: '55%',
                    borderBottom: '1px solid #c7c9c9',
                    padding: '10px'
                  }}>
                    <p style={{ fontFamily: "cursive", fontSize: 20, fontWeight: "bold" }}>
                      {store.storeName}
                    </p>
                    <p style={{ fontFamily: "poppine", fontSize: 20, fontWeight: "bold" }}>
                      {store.storeTitle}
                    </p>
                  </div>
                  <Button
                variant="danger"
                onClick={()=>handleDelete(store)}
                disabled={loading}
                style={{ marginLeft: '10px' }}
              >
                {loading ? 'Deleting...' : 'Delete'}
              </Button>
              <Button
                variant="primary"
                onClick={() => goaddstorepage(store)}
                disabled={loading}
              >
                {loading ? 'Updating...' : 'Update'}
              </Button>
                </div>
              </div>
              
            ))}
           
          </div>
        </div>
      )}
     
      </Scrollbars>
      <ToastContainer />
    </div>
  );
}

export default CreateShop;
