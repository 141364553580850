import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFirestore, collection, query, orderBy, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';

function SelectStoreInCategories() {
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [storeNames, setStoreNames] = useState({});
  const [loadingStoreNames, setLoadingStoreNames] = useState(false);

  // Fetch categories from Firestore
  const fetchCategories = async () => {
    try {
      const firestore = getFirestore();
      const categoriesCollection = collection(firestore, 'Categories');
      const categoriesQuery = query(categoriesCollection, orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(categoriesQuery);
      const categoriesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesList);
    } catch (error) {
      console.error('Error fetching categories:', error);
      toast.error('Failed to fetch categories.');
    } finally {
      setLoadingCategories(false);
    }
  };

  // Fetch store names based on provided store IDs
  const fetchStoreNames = async (storeIds) => {
    if (!storeIds || storeIds.length === 0) return;
    
    setLoadingStoreNames(true);
    try {
      const firestore = getFirestore();
      const storeNamesObj = {};
      await Promise.all(
        storeIds.map(async (storeId) => {
          const storeDocRef = doc(firestore, 'Store', storeId);
          const storeDoc = await getDoc(storeDocRef); // Corrected to use getDoc
          if (storeDoc.exists()) {
            storeNamesObj[storeId] = storeDoc.data().storeName || 'No Name'; // Ensure correct field name
          } else {
            storeNamesObj[storeId] = 'Name not available';
          }
        })
      );
      setStoreNames(storeNamesObj);
    } catch (error) {
      console.error('Error fetching store names:', error);
      toast.error('Failed to fetch store names.');
    } finally {
      setLoadingStoreNames(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Handle category click to display its stores
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    fetchStoreNames(category.stores || []); // Handle case where stores might be undefined
    setShowModal(true);
  };

  // Handle deleting a store from a category
  const handleDeleteStore = async (storeId) => {
    if (selectedCategory) {
      try {
        const firestore = getFirestore();
        const categoryDocRef = doc(firestore, 'Categories', selectedCategory.id);
        const updatedStores = selectedCategory.stores.filter((id) => id !== storeId);
        await updateDoc(categoryDocRef, { stores: updatedStores });
        setSelectedCategory({ ...selectedCategory, stores: updatedStores });
        toast.success('Store deleted from category.');
      } catch (error) {
        console.error('Error deleting store from category:', error);
        toast.error('Failed to delete store from category.');
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="d-flex flex-wrap justify-content-center">
        {loadingCategories ? (
          <p>Loading...</p>
        ) : (
          categories.map((category) => (
            <div
              key={category.id}
              className="card m-3"
              style={{ width: '18rem', cursor: 'pointer' }}
              onClick={() => handleCategoryClick(category)}
            >
              <div className="card-body">
                <h5 className="card-title">{category.createCategories || 'No Title'}</h5>
              </div>
            </div>
          ))
        )}
      </div>

      {selectedCategory && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedCategory.createCategories}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              {selectedCategory.stores && selectedCategory.stores.length > 0 ? (
                selectedCategory.stores.map((storeId) => (
                  <ListGroup.Item key={storeId} className="d-flex justify-content-between align-items-center">
                    {storeNames[storeId] || (loadingStoreNames ? 'Loading...' : 'Name not available')}
                    <Button variant="danger" onClick={() => handleDeleteStore(storeId)}>
                      Delete
                    </Button>
                  </ListGroup.Item>
                ))
              ) : (
                <p>No stores in this category.</p>
              )}
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default SelectStoreInCategories;
