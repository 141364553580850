import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './screen/Home';
import Shop from './screen/Shop';
import Categories from './screen/Categories';
import OpenStore from './screen/OpenStore';
import Login from './screen/Login';
import CreateShop from './screen/Admin/CreateShop';
import AddCoupon from './screen/Admin/AddCoupon';
import Sidebar from './screen/Component/Sidebar';
import StoreDetails from './screen/Admin/StoreDetails';
import AddCouponMore from './screen/Admin/AddCouponMore';
import AddSale from './screen/Admin/AddSale';
import AddMoreSales from './screen/Admin/AddMoreSales';
import SelectShop from './screen/Admin/SelectShop';
import ViewShop from './screen/Admin/ViewShop';
import CreateCategories from './screen/Admin/CreateCategories';
import SelectStoreInCategories from './screen/Admin/SelectStoreInCategories';
import ViewCategories from './screen/ViewCategories';
import PrivateRoute from './screen/Component/PrivateRoute';
import { AuthProvider } from './screen/Component/AuthContext';
import { Helmet } from 'react-helmet';
import TextPage from './screen/TextPage';
import AddStore from './screen/Admin/AddStore';
import Imprint from './screen/Imprint';
import Dataprotection from './screen/Dataprotection';
import TopCoupon from './screen/TopCoupon';
import Blog from './screen/Blog';
import SelectTopCoupon from './screen/Admin/SelectTopCoupon';
import AddTopCoupon from './screen/Admin/AddTopCoupon';
import DeleteTopCoupon from './screen/Admin/DeleteTopCoupon';


function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
      <Helmet>
        <title>Getgutschein</title>
        <meta 
        name='description'
        content='Holen Sie sich den Coupon für mehr Rabatt in jedem Geschäft'
 
        />
      </Helmet>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/shop' element={<Shop />} />
          <Route path='/OpenStore/:storeId/:storeName' element={<OpenStore />} />
          <Route path='/categories' element={<Categories />} />
          <Route path='/TextPage' element={<TextPage />} />
          <Route path='/Impressum' element={<Imprint />} />
          <Route path='/Dataprotection' element={<Dataprotection />} />
          <Route path='/TopCoupon' element={<TopCoupon />} />
          <Route path='/Blog' element={<Blog />} />

          <Route path='/categories/:categoriesName' element={<ViewCategories />} />
          <Route path='/admin/safe' element={<Login />} />

          <Route path='/admin' element={<PrivateRoute><Sidebar /></PrivateRoute>}>
            <Route path='create-shop' element={<CreateShop />} />
            
            <Route path='AddStore' element={<AddStore />} />
            <Route path='add-coupon' element={<AddCoupon />} />
            <Route path='store-details' element={<StoreDetails />} />
            <Route path='AddCouponMore' element={<AddCouponMore />} />
            <Route path='AddSale' element={<AddSale />} />
            <Route path='AddMoreSales' element={<AddMoreSales />} />
            <Route path='SelectShop' element={<SelectShop />} />
            <Route path='ViewShop' element={<ViewShop />} />
            <Route path='CreateCategories' element={<CreateCategories />} />
            <Route path='SelectStoreInCategories' element={<SelectStoreInCategories />} />
            <Route path='SelectTopCoupon' element={<SelectTopCoupon />} />
            <Route path='AddTopCoupon' element={<AddTopCoupon />} />
            <Route path='DeleteTopCoupon' element={<DeleteTopCoupon />} />
         
            
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
