import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Modal, Button } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css/autoplay';
import '../../App.css';
import { EffectCoverflow, Pagination,Autoplay } from 'swiper/modules';
import axios from 'axios';
import { baseUrl } from '../../BaseUrl';
import Aos from 'aos';

function HeroSection() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  // const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const getDataFromDB = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/createStore`);
      const result = response.data;

      // Sort data by create_time in descending order
      const sortedData = result.sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      setStores(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    getDataFromDB();
  }, []);

  useEffect(() => {
    const results = stores.filter(store =>
      store.storeName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredStores(results);
  }, [searchTerm, stores]);

  // const toggleModal = () => {
  //   setShowModal(!showModal);
  // };

  const handleStoreClick = (storeId,storename,store) => {
   
    navigate(`/OpenStore/${storeId}/${storename}`, { state: store });
    setSelectedStoreId(storeId);
  };
  useEffect(() => {
    Aos.init(); // Initialize AOS
  }, []);
  return (
    <div className='hero-section'
  
    style={{
      backgroundImage: "url(/bb.png)",
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      position: 'relative',
      overflow: 'hidden',
    }}>
      <div className='container'>
        <nav className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: 'transparent' }}>
          <Link to={'/'} className="navbar-brand" style={{ fontFamily: "Poppins", fontSize: "2.3rem", textDecoration: "none", color: "black", fontWeight: "bolder" ,}}>
            <img src='/newlogo.png' alt='Getgutschein' style={{ maxWidth: "100%", height: "75px", borderRadius: 10 }} />
          </Link>
          <button className="navbar-toggler" type="button" onClick={toggleModal}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav" style={{marginBottom:25}}>
            <ul className="navbar-nav">
            <li className="nav-item">
                <Link to={'/TopCoupon'} className="nav-link" style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                  <img src='/gift-voucher.png' alt='store' style={{ width: 'auto', height: '1.9rem', marginRight: "5px" }} />
                  Top Gutschein
                </Link>
              </li>
              <li className="nav-item">
                <Link to={'/shop'} className="nav-link" style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                  <img src='/store.png' alt='store' style={{ width: 'auto', height: '1.8rem', marginRight: "5px" }} />
                  Alle shops
                </Link>
              </li>
              <li className="nav-item">
                <Link to={'/categories'} className="nav-link" style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                  <img src='/cate.png' alt='categories' style={{ width: 'auto', height: '1.8rem', marginRight: "5px" }} />
                  Kategorien
                </Link>
              </li>
              <li className="nav-item">
                <Link to={'/Blog'} className="nav-link" style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                  <img src='/blog.png' alt='blog' style={{ width: 'auto', height: '1.8rem', marginRight: "5px" }} />
                  Blogs
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        <div className='col-12' style={{ fontFamily: "Poppins", marginTop: "2%", fontSize: "2rem", fontWeight: "bold", color: "blue" }}>Alle Gutscheine und Rabatte auf<br/>einen Blick</div>
        <nav className="navbar navbar-light ml-3">
          <form className="form-inline w-100">
            <div className="input-group w-50">
              <input
                className="form-control search-input w-20"
                style={{ border: "0.7px solid green", width: "50%" }}
                type="search"
                placeholder="Search By Shop & Categories"
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="input-group-append">
                <button className="btn btn-outline-success" type="button">
                  Search
                </button>
              </div>
            </div>
          </form>
        </nav>
        {searchTerm && (
          <div className="search-results">
            <ul className="list-group">
              {filteredStores.map(store => (
                <li
                  key={store.id}
                  className="list-group-item"
                  onClick={() => handleStoreClick(store.id,store.storeName,store)}
                  style={{ cursor: 'pointer' }}
                >
                  <img src={`${store.StoreImage}`} alt={store.storeName} style={{width:60,height:30,paddingRight:0}}/>
                  {store.storeName}
                </li>
              ))}
            </ul>
          </div>
        )}
        {selectedStoreId && (
          <div className="selected-store-id">
            Selected Store ID: {selectedStoreId}
          </div>
        )}
        <div className='col-12' style={{ fontFamily: "Poppins", fontSize: "1rem", fontWeight: "normal", color: "black", paddingTop: '0.5%' }}>
        Uns allen wohlbekannt sind die sogenannten Printcoupons oder Rabatt <br/>Marken aus diversen Zeitungsanzeigen.
        </div>
        <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="navbar-nav">
          <li className="nav-item">
                <Link to={'/TopCoupon'} className="nav-link" style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                  <img src='/gift-voucher.png' alt='store' style={{ width: 'auto', height: '1.9rem', marginRight: "5px" }} />
                  Top Gutschein
                </Link>
              </li>
            <li className="nav-item">
              <Link to={'/shop'} className="nav-link" onClick={closeModal} style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                <img src='/store.png' alt='store' style={{ width: 'auto', height: '1.8rem', marginRight: "5px" }} />
                SHOP
              </Link>
            </li>
            <li className="nav-item">
              <Link to={'/categories'} className="nav-link" onClick={closeModal} style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                <img src='/cate.png' alt='categories' style={{ width: 'auto', height: '1.8rem', marginRight: "5px" }} />
                CATEGORIES
              </Link>
            </li>
            <li className="nav-item">
                <Link to={'/Blog'} className="nav-link" style={{ fontFamily: "Poppins", fontSize: '1rem', textDecoration: "none", color: "black", fontWeight: "normal" }}>
                  <img src='/blog.png' alt='blog' style={{ width: 'auto', height: '1.8rem', marginRight: "5px" }} />
                  Blogs
                </Link>
              </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
        <div className='col-8'   data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
          
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            autoplay={{
              delay: 4000, // Change slide every 4 seconds
              disableOnInteraction: false, // Autoplay continues even after user interactions
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination,Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src='/1.jpeg' style={{objectFit: "cover" }} />
            </SwiperSlide>
            <SwiperSlide>
              <img src="/2.jpeg" style={{objectFit: "fill" }}/>
            </SwiperSlide>
            <SwiperSlide>
              <img src="/3.jpeg" style={{objectFit: "contain" }}/>
            </SwiperSlide>
            <SwiperSlide>
              <img src="/4.jpeg" style={{objectFit: "contain" }}/>
            </SwiperSlide>
            <SwiperSlide>
              <img src="/5.jpeg" style={{objectFit: "contain" }}/>
            </SwiperSlide>
            <SwiperSlide>
              <img src="/6.jpeg" style={{objectFit: "contain" }}/>
            </SwiperSlide>
            <SwiperSlide>
              <img src="/7.jpeg" style={{objectFit: "contain" }}/>
            </SwiperSlide>
            <SwiperSlide>
              <img src="/8.jpeg" style={{objectFit: "contain" }}/>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
