import React, { useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../BaseUrl';

function AddTopCoupon() {
  const location = useLocation();
  const store = location.state;
  const [loadingCoupons, setLoadingCoupons] = useState(true);
  const [coupons, setCoupons] = useState([]);

  const fetchCoupons = async () => {
    setLoadingCoupons(true);
    try {
      const response = await axios.get(`${baseUrl}api/coupons/store/${store.id}`);
      let result = response.data;
      
      result = result.map(coupon => ({
        ...coupon,
        timestamp: new Date(coupon.timestamp)
      }));
      
      const sortedCoupons = result.sort((a, b) => b.timestamp - a.timestamp);
      setCoupons(sortedCoupons);
    } catch (error) {
      console.log('Error fetching coupons:', error);
    } finally {
      setLoadingCoupons(false);
    }
  };
  
  useEffect(() => {
    fetchCoupons();
  }, []);

  const handleSelectClick = async (couponId) => {
    try {
      const response = await axios.post(`${baseUrl}api/SelectTopCoupon`, {
        Store_ID: store.id,
        Coupon_id: couponId
      });
      alert('Selection stored successfully');
    } catch (error) {
      console.error('Error storing selection:', error);
      alert('Error storing selection');
    }
  };

  return (
    <div style={{ height: '100vh', overflow: 'hidden', backgroundColor: "#f3f9f9", borderRadius: 10 }}>
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        autoHide
        autoHideTimeout={10}
        autoHideDuration={10}
        thumbMinSize={10}
        universal={true}
        renderThumbVertical={({ style, ...props }) =>
          <div {...props} style={{ ...style, backgroundColor: 'black', borderRadius: '4px' }} />
        }
      >
        {loadingCoupons ? (
          <div>Loading...</div>
        ) : (
          <Row className="mb-3">
            {coupons.map((coupon, index) => (
              <Col key={index} sm={12} md={6} lg={4}>
                <Card style={{ margin: '10px', position: 'relative' }}>
                  <Card.Body style={{ padding: 0 }}>
                    {/* Coupon Image (main image) */}
                    <img
                      src={`${coupon.CouponImage}`}
                      alt={coupon.TitleCoupon}
                      style={{
                        width: '100%',
                        height: '200px',
                        objectFit: 'cover',
                        borderTopLeftRadius: '5px',
                        borderTopRightRadius: '5px',
                      }}
                    />
                    {/* Store Image */}
                    <img
                      src={`${store.StoreImage}`}
                      alt={store.storeName}
                      style={{
                        width: 80,
                        height: 80,
                        position: 'absolute',
                        top: 120,
                        left: 0,
                        objectFit: "contain",
                        border: '2px solid white', // Add a white border to make it stand out
                      }}
                    />
                    <Card.Text style={{ padding: '10px', paddingTop: '60px' }}>
                      <strong>{coupon.TitleCoupon}</strong><br/>
                    </Card.Text>
                    {/* Select Button */}
                    <Button
                      variant="primary"
                      style={{
                        position: 'absolute',
                        bottom: 10,
                        right: 10,
                      }}
                      onClick={() => handleSelectClick(coupon.id)}
                    >
                      Select
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Scrollbars>
    </div>
  );
}

export default AddTopCoupon;
