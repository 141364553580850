import React from 'react';
import { useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
function StoreDetails() {
  const location = useLocation();
  const store = location.state;

  return (
      <div style={{ height: '100vh', overflow: 'hidden', backgroundColor: "#f3f9f9", borderRadius: 10 }}>
      <Scrollbars
      style={{ width: '100%', height: '100%' }}
      autoHide
      autoHideTimeout={10}
      autoHideDuration={10}
      thumbMinSize={10}
      universal={true}
      renderThumbVertical={({ style, ...props }) =>
        <div {...props} style={{ ...style, backgroundColor: 'black', borderRadius: '4px' }} />
      }
    >
      <h1>Store Details</h1>
      <img src={store.storeLogo} alt={store.storeName} style={{ width: 200, height: 80 }} />
      <p><strong>Store Name:</strong> {store.storeName}</p>
      <p><strong>AllilatedLink:</strong> <a href={store.AllilatedLink} target="_blank" rel="noopener noreferrer">{store.AllilatedLink}</a></p>
      
      <p><strong>Store URL:</strong> <a href={store.storeUrl} target="_blank" rel="noopener noreferrer">{store.storeUrl}</a></p>
      <p><strong>Store Title:</strong> {store.storeTitle}</p>
      <p><strong>Store Second Title:</strong> {store.storeSecondTitle}</p>
      <p><strong>Store Description:</strong> {store.storeDescription}</p>
    </Scrollbars>
</div>
  );
}

export default StoreDetails;
