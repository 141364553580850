import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { baseUrl } from '../../BaseUrl';

function SelectShop() {
  const [fetchAllStoreData, setFetchAllStoreData] = useState([]);
  const [loadingStores, setLoadingStores] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStores, setSelectedStores] = useState({});
  const [filterSelectstore, setFilterSelectstore] = useState([]);

  useEffect(() => {
    getDataFromDB();
    getSelectedStore();
  }, []);

  const getDataFromDB = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/createStore`);
      const result = response.data;
      const sortedData = result.sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      setFetchAllStoreData(sortedData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    } finally {
      setLoadingStores(false);
    }
  };

  const getSelectedStore = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/SelectStore`);
      const result = response.data;
      const sortedData = result.sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      setFilterSelectstore(sortedData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  const handleStoreClick = async (store) => {
    // Check if the store is already selected
    const isAlreadySelected = filterSelectstore.some(selectedStore => selectedStore.storeID === store.id);

    if (isAlreadySelected) {
      toast.warn("Store is already selected.");
      return;
    }

    const selectStore = {
      storeID: store.id,
      storeName: store.storeName,
      simpleStoreURL: store.simpleStoreURL,
      StoreImage: store.StoreImage,
      affiliatedStoreURL: store.affiliatedStoreURL,
      storeTittle: store.storeTittle,
      storeSecondTittle: store.storeSecondTittle,
      StoreDescription: store.StoreDescription,
      addMetaTaq: store.addMetaTaq,
      storeSEODescription: store.storeSEODescription
    };

    try {
      setLoading(true);
      await axios.post(`${baseUrl}api/SelectStore`, selectStore, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSelectedStores(prevState => ({ ...prevState, [store.id]: true }));
      toast.success("Store selected successfully.");
      getSelectedStore(); // Update the selected stores after selection
    } catch (error) {
      console.error('Error selecting store: ', error);
      toast.warn("Error selecting store. May be this store is already selected.");
    } finally {
      setLoading(false);
    }
  };

  const filteredStores = fetchAllStoreData.filter(store =>
    store.storeName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ height: '100vh', overflow: 'hidden', backgroundColor: "#f3f9f9", borderRadius: 10 }}>
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        autoHide
        autoHideTimeout={10}
        autoHideDuration={10}
        thumbMinSize={10}
        universal={true}
        renderThumbVertical={({ style, ...props }) =>
          <div {...props} style={{ ...style, backgroundColor: 'black', borderRadius: '4px' }} />
        }
      >
        {loadingStores || loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100%', backgroundColor: '#f3f9f9' }}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            <h2 className='ml-2' style={{ fontFamily: "fantasy" }}>All Stores</h2>
            <Form className="mb-3">
              <Form.Control
                type="text"
                placeholder="Search stores by name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "50%", borderWidth: '1px solid black', margin: "1%" }}
              />
            </Form>
            <div className="row row-cols-1 row-cols-md-3 g-3">
              {filteredStores.map((store) => (
                <div
                  className='col-12 col-md-4 mb-3'
                  key={store.id}
                  style={{ display: 'flex', justifyContent: 'center', cursor: "pointer" }}
                  onClick={() => handleStoreClick(store)}
                >
                  <div style={{
                    width: "100%",
                    backgroundColor: "#ffffff",
                    height: "35vh",
                    borderRadius: 8,
                    border: '1px solid #c7c9c9',
                    margin: '0 10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.7)'
                  }}>
                    <div style={{
                      width: "100%",
                      height: '25%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderBottom: '1px solid #c7c9c9'
                    }}>
                      <img src={`${store.StoreImage}`} alt={store.storeName} style={{ width: 90, height: 37 }} />
                    </div>
                    <div style={{
                      width: "100%",
                      height: '55%',
                      borderBottom: '1px solid #c7c9c9',
                      padding: '10px'
                    }}>
                      <p style={{ fontFamily: "cursive", fontSize: 20, fontWeight: "bold" }}>
                        {store.storeName}
                      </p>
                      <p style={{ fontFamily: "poppins", fontSize: 20, fontWeight: "bold" }}>
                        {store.storeTittle}
                      </p>
                    </div>
                    <div className='bottombutton' style={{
                      width: "100%",
                      height: '15%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontFamily: "Poppins",
                      fontSize: 18,
                      fontWeight: "initial",
                      color: selectedStores[store.id] ? 'green' : 'initial'
                    }}>
                      {selectedStores[store.id] ? 'Selected' : 'Unselected'}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Scrollbars>
      <ToastContainer position="top-right" />
    </div>
  );
}

export default SelectShop;
