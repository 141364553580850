import React, { useEffect, useState } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { firebase } from '../../env';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation } from 'react-router-dom';
import { FaPercent, FaEuroSign } from "react-icons/fa";
import Select from 'react-select';
import { PiEmptyLight } from "react-icons/pi";
import axios from 'axios';
import { baseUrl } from '../../BaseUrl';
function AddMoreSales() {
  const location = useLocation();
  const store = location.state;
  // console.log("this is ",store)
  const [show, setShow] = useState(false);
  const [discounts, setDiscounts] = useState('');
  const [titleSale, setTitleSale] = useState('');
  const [startDate, setStartDate] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [storeUrl, setStoreUrl] = useState('');
  const [discountHeading, setDiscountHeading] = useState('');
  const [saleDescription, setSaleDescription] = useState('');
  const [discountsIn, setDiscountsIn] = useState();
  const [addTopOffer, setAddTopOffer] = useState(false);
  const [topOfferDetails, setTopOfferDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const [sales, setSales] = useState([]);
  const [editSaleId, setEditSaleId] = useState(null);
  const [loadingSales, setLoadingSales] = useState(true);

  const options = [
    { value: 'percentage', label: <div><FaPercent /> Percentage</div> },
    { value: 'Euro', label: <div><FaEuroSign /> Euro</div> },
    { value: '', label: <div><PiEmptyLight /> Nothing</div> },
  ];

  const handleSelectChange = (selectedOption) => {
    setDiscountsIn(selectedOption.value);
  };

  const cleanModel = () => {
    setDiscounts('');
    setTitleSale('');
    setStartDate('');
    setExpireDate('');
    setStoreUrl('');
    setSaleDescription('');
    setDiscountsIn();
    setDiscountHeading('');
    setAddTopOffer(false);
    setTopOfferDetails('');
    setEditSaleId(null);
  };

  const fetchSales = async () => {
    setLoadingSales(true);
    try {
     const response= await axios.get(`${baseUrl}api/Sales/store/${store.id}`)
     const result = response.data;
     const sortedData = result.sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      setSales(sortedData);
    } catch (error) {
      console.log('Error fetching sales:', error);
    } finally {
      setLoadingSales(false);
    }
  };
console.log(sales)
  const createOrUpdateSale = async () => {
    setLoading(true);
  if(editSaleId){
    try {
      const saleData = {
        StoreId: store.id,
        titleSale: titleSale,
        discounts: discounts,
        discountsIn: discountsIn,
        StartDate: startDate,
        ExpireDate: expireDate,
        StoreURL: storeUrl,
        DiscountsHeading: discountHeading,
        saleDescription: saleDescription,
        ConfirmOffer: true,
        topOffer: addTopOffer ? topOfferDetails : '',
      };
  
      await axios.put(`${baseUrl}api/Sales/${editSaleId}`, saleData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      toast.success('Sale Added Successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setEditSaleId();
      cleanModel();
      setShow(false);
      fetchSales();
    } catch (error) {
      toast.error('Error Saving Sale', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  else{
    try {
      const saleData = {
        StoreId: store.id,
        titleSale: titleSale,
        discounts: discounts,
        discountsIn: discountsIn,
        StartDate: startDate,
        ExpireDate: expireDate,
        StoreURL: storeUrl,
        DiscountsHeading: discountHeading,
        saleDescription: saleDescription,
        ConfirmOffer: true,
        topOffer: addTopOffer ? topOfferDetails : '',
      };
  
      await axios.post(`${baseUrl}api/createSales`, saleData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      toast.success('Sale Added Successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  
      cleanModel();
      setShow(false);
      fetchSales();
    } catch (error) {
      toast.error('Error Saving Sale', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }}
  };

  const handleSave = async () => {
    await createOrUpdateSale();
  };

  const handleEdit = (sale) => {
    setTitleSale(sale.titleSale);
    setDiscounts(sale.discounts);
    setStartDate(sale.StartDate);
    setExpireDate(sale.ExpireDate);
    setStoreUrl(sale.StoreURL);
    setSaleDescription(sale.saleDescription);
    setDiscountsIn(sale.discountsIn);
    setDiscountHeading(sale.DiscountsHeading)
    setAddTopOffer(sale.addTopOffer);
    setTopOfferDetails(sale.topOffer || '');
    setEditSaleId(sale.id);
    setShow(true);
  };
const updateSale=async()=>{
  try{
const response=await axios.put(`${baseUrl}api/Sales/${editSaleId}`,)

  }catch(error){

  }
}

  const handleDelete = async (saleId) => {
   
    if (window.confirm("Are you sure you want to delete this sale?")) {
      try {
      await axios.delete(`${baseUrl}api/Sales/${saleId}`)
        toast.success('Sale Deleted Successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchSales();
      } catch (error) {
        toast.error('Error Deleting Sale', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchSales();
  }, []);

  return (
    <div style={{ height: '100vh', overflow: 'hidden', backgroundColor: "#f3f9f9", borderRadius: 10 }}>
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        thumbMinSize={10}
        universal={true}
        renderThumbVertical={({ style, ...props }) =>
          <div {...props} style={{ ...style, backgroundColor: 'black', borderRadius: '4px' }} />
        }
      >
        <Row className="align-items-center" style={{ padding: "20px" }}>
          <Col xs={12} md={3}>
            <img src={`${store.StoreImage}`} alt={store.storeName} style={{ width: 200, height: 80 }} />
          </Col>
          <Col xs={12} md={6}>
            <p>Store Name: <strong>{store.storeName}</strong></p>
          </Col>
          <Col xs={12} md={3} className="d-flex justify-content-end">
            <Button type="button" className="btn btn-primary" onClick={() => setShow(true)}>
              {editSaleId ? 'Edit Sale' : 'Add Sale'}
            </Button>
          </Col>
        </Row>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          scrollable
          size="lg"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title" style={{ color: "green" }}>
              {editSaleId ? 'Edit Sale' : 'Add Sale'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "justify" }}>
            <Form>
              <Row>
                <Col>
                  <Form.Group controlId="formTitleSale">
                    <Form.Label>Title Sale</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Sale Title"
                      value={titleSale}
                      onChange={(e) => setTitleSale(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formSaleDescription">
                    <Form.Label>Sale Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Sale Description"
                      value={saleDescription}
                      onChange={(e) => setSaleDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formDiscounts">
                    <Form.Label>Discounts</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Discounts"
                      value={discounts}
                      onChange={(e) => setDiscounts(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formDiscountsIn">
                    <Form.Label>Discounts In</Form.Label>
                    <Select
                      options={options}
                      onChange={handleSelectChange}
                      value={options.find(option => option.value === discountsIn)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formStartDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formExpireDate">
                    <Form.Label>Expire Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={expireDate}
                      onChange={(e) => setExpireDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              
                <Row>
                  <Col>
                  <Form.Group controlId="formStoreUrl">
                <Form.Label>Store URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Store URL"
                  value={storeUrl}
                  onChange={(e) => setStoreUrl(e.target.value)}
                />
              </Form.Group>
                  </Col>
                <Col>
                <Form.Group controlId="formStoreUrl">
                <Form.Label>Discount Heading</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Discount Heading"
                  value={discountHeading}
                  onChange={(e) => setDiscountHeading(e.target.value)}
                />
              </Form.Group>
                
                </Col>
                </Row>
           
              <Form.Group controlId="formTopOfferCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Confirm This is Top Offer"
                  checked={addTopOffer}
                  onChange={(e) => setAddTopOffer(e.target.checked)}
                />
              </Form.Group>
              {addTopOffer && (
                <Form.Group controlId="formTopOfferDetails">
                  <Form.Label>Top Offer Details</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Top Offer Details"
                    value={topOfferDetails}
                    onChange={(e) => setTopOfferDetails(e.target.value)}
                  />
                </Form.Group>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={handleSave}
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save Sale'}
            </Button>
          </Modal.Footer>
        </Modal>

        {loadingSales ? (
          <p>Loading sales...</p>
        ) : (
          <Row>
            {sales.map((sale) => (
              <Col key={sale.id} xs={12} md={6} lg={4} xl={3}>
                <Card className="mb-4">
                  <Card.Body>
                    <Card.Title>{sale.titleSale}</Card.Title>
                    <Card.Text>{sale.saleDescription}</Card.Text>
                    <Card.Text>Discounts: {sale.discounts}</Card.Text>
                    <Card.Text>Discounts In: {sale.discountsIn}</Card.Text>
                    <Card.Text>Start Date: {sale.StartDate}</Card.Text>
                    <Card.Text>Expire Date: {sale.ExpireDate}</Card.Text>
                    <Card.Text>Store URL: {sale.StoreURL}</Card.Text>
                    {sale.addTopOffer && (
                      <Card.Text><strong>Top Offer Details:</strong> {sale.topOfferDetails}</Card.Text>
                    )}
                    <Button
                      variant="info"
                      onClick={() => handleEdit(sale)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      className="ms-2"
                      onClick={() => handleDelete(sale.id)}
                    >
                      Delete
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Scrollbars>
      <ToastContainer />
    </div>
  );
}

export default AddMoreSales;
