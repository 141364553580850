import React, { useState, useEffect } from 'react';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFirestore, getDoc, doc, collection, query, orderBy, getDocs } from 'firebase/firestore';
import { FaEuroSign, FaPercent } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

function ViewCategories() {
  const navigate = useNavigate();
  const location = useLocation();
  const categoriesstore = location.state || []; // Added default value
  const [storeData, setStoreData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Added currentPage state
  const [storesPerPage] = useState(6); // Set the number of stores per page
  const firestore = getFirestore();

  useEffect(() => {
    const fetchStoreData = async () => {
      const storePromises = categoriesstore.map(async (storeId) => {
        const storeDoc = await getDoc(doc(firestore, `Store/${storeId}`));
        if (storeDoc.exists()) {
          const couponsCollection = collection(firestore, `Store/${storeId}/coupons`);
          const couponsQuery = query(couponsCollection, orderBy('timestamp', 'desc'));
          const couponsSnapshot = await getDocs(couponsQuery);
          const coupons = couponsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          const latestCoupon = coupons[0] || null;
          return { id: storeDoc.id, ...storeDoc.data(), latestCoupon };
        } else {
          return null;
        }
      });

      const stores = await Promise.all(storePromises);
      setStoreData(stores.filter(store => store !== null));
    };

    fetchStoreData();
  }, [categoriesstore, firestore]);

  // Pagination logic
  const indexOfLastStore = (currentPage + 1) * storesPerPage;
  const indexOfFirstStore = indexOfLastStore - storesPerPage;
  const currentStores = storeData.slice(indexOfFirstStore, indexOfLastStore);

  const handleGridClick = (store) => {
    navigate(`/OpenStore/${store.id}/${store.storeName}`, { state: store });
  };

  return (
    <>
      <Navbar />
      <Helmet>
        <title> Categories - Getgutschein</title>
        <meta name='description' content='Genießen Sie die besten Angebote und Verkäufe mit saisonalen Gutscheinen und Rabattcodes. Viele nicht saisonale Rabattangebote'/>
      </Helmet>
      <div className="container">
        {storeData.length === 0 ? (
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            marginTop: 0,
            marginBottom: 0
          }}>
            <img src='/secdinglodar.gif' alt='loading' style={{ width: 100, height: 100 }} />
          </div>
        ) : (
          <>
            {currentStores.length === 0 ? (
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                marginTop: 0,
                marginBottom: 0,
                fontSize: 24,
                fontFamily: "Poppins"
              }}>
                No stores available
              </div>
            ) : (
              <div className="row row-cols-1 row-cols-md-3 g-1 mt-10" style={{ marginTop: 10 }}>
                {currentStores.map((store) => (
                  <div className='col-12 col-md-3 mb-3' key={store.id} style={{ display: 'flex', justifyContent: 'center', cursor: "pointer" }}>
                    <div style={{
                      width: "100%",
                      backgroundColor: "#ffffff",
                      height: "48vh",
                      borderRadius: 8,
                      border: '1px solid #c7c9c9',
                      margin: '0 10px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.7)'
                    }}
                      onClick={() => handleGridClick(store)}
                    >
                      <div style={{
                        width: "100%",
                        height: '30%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderBottom: '1px solid #c7c9c9'
                      }}>
                        <img src={store.storeLogo} alt={store.storeName} loading="lazy" style={{ width: 120, height: 60 }} />
                      </div>
                      <div style={{
                        width: "100%",
                        height: '50%',
                        borderBottom: '1px solid #c7c9c9',
                        padding: '10px'
                      }}>
                        {store.latestCoupon && (
                          <>
                            <p style={{ fontFamily: "cursive", fontSize: 20, fontWeight: "bold" }}>
                              {store.latestCoupon.discounts}{store.latestCoupon.discountsIn === "Euro" ? <FaEuroSign /> : <FaPercent />}<b style={{ fontSize: 25, fontFamily: "monospace" }}> Discount</b>
                            </p>
                            <div style={{ fontFamily: "Poppins", fontSize: 17, fontWeight: "700", paddingBottom: '10px' }}>
                              {store.latestCoupon.titleCoupon}
                            </div>
                          </>
                        )}
                      </div>
                      <div className='bottombutton' style={{
                        width: "100%",
                        height: '20%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: "Poppins",
                        fontSize: 18,
                        fontWeight: "initial"
                      }}>
                        To the campaign
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default ViewCategories;
