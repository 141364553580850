import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { baseUrl } from '../BaseUrl';

function Shop() {
  const navigate = useNavigate();
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedLetter, setSelectedLetter] = useState('');

  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  const getDataFromDB = async () => {
    try {
      const response = await axios.get(baseUrl+`api/createStore`);
      const result = response.data;

      // Sort data by create_time in descending order
      const sortedData = result.sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      setStores(sortedData);
      setFilteredStores(sortedData); // Initialize filteredStores with sortedData
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataFromDB();
  }, []);

  useEffect(() => {
    let results = stores.filter(store =>
      store.storeName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (selectedLetter) {
      results = results.filter(store => store.storeName.toUpperCase().startsWith(selectedLetter));
    }

    setFilteredStores(results);
  }, [searchTerm, stores, selectedLetter]);

  const handleStoreClick = (store) => {
    navigate(`/OpenStore/${store.id}/${store.storeName}`, { state: store });
  };

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');

  return (
    <div style={{ backgroundColor: "white", height: "auto" }}>
      <Helmet>
        <title>Shop - Getgutschein</title>
        <meta name='description' content='Genießen Sie die besten Angebote und Verkäufe mit saisonalen Gutscheinen und Rabattcodes. Viele nicht saisonale Rabattangebote' />
      </Helmet>
      <Navbar />
      <div className="container mt-4">
        <div className="row mb-4">
          <h3 style={{ fontFamily: "Poppins", fontWeight: "600" }}>Search by Store Name</h3>
          <div className="col-12">
            <input
              type="text"
              className="form-control"
              placeholder="Search stores..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        
        <div className="row mb-4">
          <h4 style={{ fontFamily: "Poppins", fontWeight: "600", marginBottom: 10 }}>Find by Letter</h4>
          <div className="d-flex flex-wrap" style={{ gap: '5px' }}>
            {alphabet.map(letter => (
              <button
                key={letter}
                className={`btn btn-outline-primary me-2 mb-2 ${selectedLetter === letter ? 'active' : ''}`}
                onClick={() => setSelectedLetter(selectedLetter === letter ? '' : letter)}
              >
                {letter}
              </button>
            ))}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <img src='/shopping-bag.gif' style={{width:100,height:100}} alt="Loading"/>
              </div>
            ) : (
              <div className="row">
                {filteredStores.map(store => (
                  <div 
                    key={store.id}
                    className="col-md-3 mb-4"
                    data-aos="zoom-in-up"
                  >
                    <div 
                      className="card" 
                      style={{ 
                        cursor: 'pointer', 
                        border: 'none', 
                        backgroundColor: "transparent", 
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 1)', 
                        transition: 'box-shadow 0.3s ease-in-out'
                      }} 
                      onClick={() => handleStoreClick(store)}
                    >
                      <div 
                        className="card-img-container" 
                        style={{ 
                          width: '100%', 
                          height: '180px', 
                          display: 'flex', 
                          justifyContent: 'center', 
                          alignItems: 'center', 
                          borderRadius: 5,
                          border: '1px solid #9ea0a0', 
                          marginBottom: '8px', 
                          backgroundColor: 'White'
                        }}
                      >
                        <img 
                          src={store.StoreImage} 
                          alt={store.storeName} 
                          className="card-img" 
                          style={{ 
                            maxWidth: '90%', 
                            maxHeight: '90%', 
                            objectFit: "contain" 
                          }} 
                        />
                      </div>
                      <div 
                        className="card-body text-center" 
                        style={{ 
                          backgroundColor: 'White', 
                          borderRadius: 5,
                          border: '1px solid #9ea0a0',
                          padding: '7px'
                        }}
                      >
                        <h5 className="card-title">{store.storeName}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Shop;
