import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { baseUrl } from '../../BaseUrl';

function ViewShop() {
  const [selectedStores, setSelectedStores] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDataFromDB();
  }, []);

  const getDataFromDB = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/SelectStore`);
      const result = response.data;
      const sortedData = result.sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      setSelectedStores(sortedData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (storeId) => {
    try {
      await axios.delete(`${baseUrl}api/SelectStore/${storeId}`);
      setSelectedStores((prevStores) => prevStores.filter((store) => store.id !== storeId));
      alert("Store deleted successfully");
    } catch (error) {
      console.error('Error deleting store: ', error);
      alert("Error deleting store. Please try again.");
    }
  };

  return (
    <div style={{ height: '100vh', overflow: 'hidden', backgroundColor: "#f3f9f9", borderRadius: 10 }}>
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        autoHide
        autoHideTimeout={10}
        autoHideDuration={10}
        thumbMinSize={10}
        universal={true}
        renderThumbVertical={({ style, ...props }) =>
          <div {...props} style={{ ...style, backgroundColor: 'black', borderRadius: '4px' }} />
        }
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100%', backgroundColor: '#f3f9f9' }}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            <h2 className='ml-2' style={{ fontFamily: "fantasy" }}>Selected Stores</h2>
            <div className="row row-cols-1 row-cols-md-3 g-3">
              {selectedStores.map((store) => (
                <div
                  className='col-12 col-md-4 mb-3'
                  key={store.id}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div style={{
                    width: "100%",
                    backgroundColor: "#ffffff",
                    height: "35vh",
                    borderRadius: 8,
                    border: '1px solid #c7c9c9',
                    margin: '0 10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.7)' // Add shadow here
                  }}>
                    <div style={{
                      width: "100%",
                      height: '25%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderBottom: '1px solid #c7c9c9'
                    }}>
                      <img src={`${store.StoreImage}`} alt={store.storeName} style={{ width: 90, height: 37 }} />
                    </div>
                    <div style={{
                      width: "100%",
                      height: '55%',
                      borderBottom: '1px solid #c7c9c9',
                      padding: '10px'
                    }}>
                      <p style={{ fontFamily: "cursive", fontSize: 20, fontWeight: "bold" }}>
                        {store.storeName}
                      </p>
                      <p style={{ fontFamily: "poppins", fontSize: 20, fontWeight: "bold" }}>
                        {store.storeTitle}
                      </p>
                    </div>
                    <div className='bottombutton' style={{
                      width: "100%",
                      height: '15%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontFamily: "Poppins",
                      fontSize: 18,
                      fontWeight: "initial",
                      color: 'green'
                    }}>
                      <Button variant="danger" onClick={() => handleDelete(store.id)}>Delete</Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Scrollbars>
    </div>
  );
}

export default ViewShop;
