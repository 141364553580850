import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import JoditEditor from 'jodit-react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { toast, ToastContainer } from 'react-toastify';
import { baseUrl } from '../../BaseUrl';
import { useLocation, useNavigate } from 'react-router-dom';

function AddStore() { 
  const navigate = useNavigate();
  const location = useLocation();
  const storeData = location.state?.data; // Data passed from the previous page
  console.log("this is update store", storeData);

  const [storeName, setStoreName] = useState('');
  const [storeLogo, setStoreLogo] = useState(null);
  const [storeUrl, setStoreUrl] = useState('');
  const [affiliatedStoreUrl, setAffiliatedStoreUrl] = useState('');
  const [storeTitle, setStoreTitle] = useState('');
  const [storeSecondTitle, setStoreSecondTitle] = useState('');
  const [storeDescription, setStoreDescription] = useState('');
  const [metaLine, setMetaLine] = useState("");
  const [storeSEODes, setStoreSEODes] = useState('');
  const [logoPreview, setLogoPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addCureentTime,setAddCureentTime]=useState();
  const editor = useRef(null);
  const [content, setContent] = useState('');

  // UseEffect to populate form fields with existing store data
  useEffect(() => {
    if (storeData) {
      setStoreName(storeData.storeName || '');
      setStoreUrl(storeData.simpleStoreURL || '');
      setAffiliatedStoreUrl(storeData.affiliatedStoreURL || '');
      setStoreTitle(storeData.storeTittle || '');
      setStoreSecondTitle(storeData.storeSecondTittle || '');
      setStoreDescription(storeData.StoreDescription || '');
      setMetaLine(storeData.addMetaTaq || '');
      setStoreSEODes(storeData.storeSEODescription || '');
      setLogoPreview(storeData.StoreImage || null); // Assuming the image URL is passed
      setContent(storeData.StoreDescription || ''); // Store description in the editor
    }
  }, [storeData]);

  const config = {
    readonly: false,
    toolbarSticky: false,
    language: 'en',
    buttons: [
      'source', '|', 'bold', 'italic', 'underline', 'strikethrough', '|',
      'superscript', 'subscript', '|', 'ul', 'ol', '|',
      'outdent', 'indent', '|', 'font', 'fontsize', 'brush', 'paragraph', '|',
      'image', 'video', 'table', 'link', '|', 'align', 'undo', 'redo', '\n',
      'hr', 'eraser', 'copyformat', '|', 'symbol', 'fullsize', 'print', 'about', '|',
      'cut', 'copy', 'paste', '|', 'selectall', 'preview', 'code'
    ],
    uploader: {
      insertImageAsBase64URI: true,
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
      process: function (files, editor) {
        files.forEach(file => {
          const reader = new FileReader();
          reader.onload = function (e) {
            editor.s.insertImage(e.target.result);
          };
          reader.readAsDataURL(file);
        });
      },
      error: function (e) {
        console.log('Error:', e);
      },
    },
    events: {
      // Apply responsive styles to inserted images
      afterInsertImage: (img) => {
        img.style.maxWidth = '100%';
        img.style.height = 'auto';
        img.classList.add('responsive-image'); // Optional class
      }
    }
  };
  
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setStoreLogo(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setLogoPreview(null);
    }
  };

  const cleanForm = () => {
    setStoreName('');
    setStoreLogo(null);
    setStoreUrl('');
    setAffiliatedStoreUrl('');
    setStoreTitle('');
    setStoreSecondTitle('');
    setStoreDescription('');
    setMetaLine('');
    setAddCureentTime('');
    setStoreSEODes('');
    setLogoPreview(null);
    setContent('');
  };

  const handleSave = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("storeName", storeName);
    formData.append("StoreImage", storeLogo);
    formData.append("simpleStoreURL", storeUrl);
    formData.append("affiliatedStoreURL", affiliatedStoreUrl);
    formData.append("storeTittle", storeTitle);
    formData.append("storeSecondTittle", storeSecondTitle);
    formData.append("StoreDescription", content); 
    formData.append("addMetaTaq", metaLine);
    formData.append("storeSEODescription", storeSEODes);
  
    try {
      if (storeData) {
        // Updating the store if storeData exists
        await axios.put(`${baseUrl}api/createStore/${storeData.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success('Store updated successfully!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        // Creating a new store if storeData does not exist
        await axios.post(`${baseUrl}api/createStore`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success('Store created successfully!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
  
      cleanForm();
      navigate(`/admin/create-shop`);
      
    } catch (error) {
      toast.error('Failed to save the store!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave();
  };

  return (
    <div style={{ height: '100vh', overflow: 'hidden', backgroundColor: "#f3f9f9", borderRadius: 10, padding: '20px' }}>
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        autoHide
        autoHideTimeout={10}
        autoHideDuration={10}
        thumbMinSize={10}
        universal={true}
        renderThumbVertical={({ style, ...props }) =>
          <div {...props} style={{ ...style, backgroundColor: 'black', borderRadius: '4px' }} />
        }
      >
        <form onSubmit={handleSubmit}>
          {/* Form Fields */}
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="storeName"
                  placeholder="Store Name"
                  value={storeName}
                  onChange={(e) => setStoreName(e.target.value)}
                />
                <label htmlFor="storeName">Store Name</label>
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-floating">
                <input
                  type="file"
                  className="form-control"
                  id="storeLogo"
                  onChange={handleImageUpload}
                />
                <label htmlFor="storeLogo">Store Logo</label>
              </div>
              {logoPreview && (
                <div className="mt-2">
                  <img
                    src={logoPreview}
                    alt="Store Logo Preview"
                    style={{ maxHeight: '100px', borderRadius: '10px' }}
                  />
                </div>
              )}
            </div>
          </div>
          
          {/* Additional Form Fields */}
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="storeUrl"
                  placeholder="Store URL"
                  value={storeUrl}
                  onChange={(e) => setStoreUrl(e.target.value)}
                />
                <label htmlFor="storeUrl">Store URL</label>
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="affiliatedStoreUrl"
                  placeholder="Affiliated Store URL"
                  value={affiliatedStoreUrl}
                  onChange={(e) => setAffiliatedStoreUrl(e.target.value)}
                />
                <label htmlFor="affiliatedStoreUrl">Affiliated Store URL</label>
              </div>
            </div>
          </div>

          {/* Titles */}
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="storeTitle"
                  placeholder="Store Title"
                  value={storeTitle}
                  onChange={(e) => setStoreTitle(e.target.value)}
                />
                <label htmlFor="storeTitle">Store Title</label>
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="storeSecondTitle"
                  placeholder="Store Second Title"
                  value={storeSecondTitle}
                  onChange={(e) => setStoreSecondTitle(e.target.value)}
                />
                <label htmlFor="storeSecondTitle">Store Second Title</label>
              </div>
            </div>
          </div>

          {/* SEO and Meta fields */}
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="metaLine"
              placeholder="Meta Description"
              value={metaLine}
              onChange={(e) => setMetaLine(e.target.value)}
            />
            <label htmlFor="metaLine">Meta Description</label>
          </div>

          <div className="form-floating mb-3">
            <textarea
              className="form-control"
              id="storeSEODes"
              placeholder="Store SEO Description"
              value={storeSEODes}
              onChange={(e) => setStoreSEODes(e.target.value)}
              style={{ height: '150px' }}
            />
            <label htmlFor="storeSEODes">Store SEO Description</label>
          </div>

          {/* Description Editor */}
          <div className="mb-3">
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              tabIndex={1}
              onBlur={newContent => setContent(newContent)}
            />
          </div>

          {/* Submit button */}
          <button
            type="submit"
            className="btn btn-primary"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save Store'}
          </button>
        </form>

        <ToastContainer />
      </Scrollbars>
    </div>
  );
}

export default AddStore;
