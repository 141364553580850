import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../BaseUrl';
import Scrollbars from 'react-custom-scrollbars-2';

function DeleteTopCoupon() {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTopCoupons = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/SelectTopCoupon`);
      console.log('API Response:', response.data); // Log the API response

      const uniqueCouponIds = new Set();
      const uniqueCoupons = [];

      for (const coupon of response.data) {
        if (!uniqueCouponIds.has(coupon.Coupon_id)) {
          const getStore = await axios.get(`${baseUrl}api/createStore/${coupon.Store_ID}`);
          const getAllCoupons = await axios.get(`${baseUrl}api/coupons/store/${coupon.Store_ID}/${coupon.Coupon_id}`);

          uniqueCouponIds.add(coupon.Coupon_id);

          const couponsWithStoreData = getAllCoupons.data.map(c => ({
            ...c,
            StoreImage: getStore.data.StoreImage,
            StoreName: getStore.data.StoreName,
          }));

          uniqueCoupons.push(...couponsWithStoreData);
        }
      }
      setCoupons(uniqueCoupons);
    } catch (error) {
      console.log('Error fetching coupons:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTopCoupons();
  }, []);

  const handleDeleteCoupon = async (couponId) => {
    console.log('Attempting to delete coupon with ID:', couponId); // Debugging couponId

    if (!couponId) {
      console.error('Invalid coupon ID:', couponId);
      alert('Invalid coupon ID');
      return;
    }

    try {
      await axios.delete(`${baseUrl}api/SelectTopCoupon/${couponId}`);
      // After deletion, filter out the deleted coupon from the list
      setCoupons(prevCoupons => prevCoupons.filter(c => c.Coupon_id !== couponId));
      alert("Coupon deleted successfully");
    } catch (error) {
      console.log('Error deleting coupon:', error);
      alert("Failed to delete coupon");
    }
  };

  return (
    <div style={{ height: '100vh', overflow: 'hidden', backgroundColor: "#f3f9f9", borderRadius: 10 }}>
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        autoHide
        autoHideTimeout={10}
        autoHideDuration={10}
        thumbMinSize={10}
        universal={true}
        renderThumbVertical={({ style, ...props }) =>
          <div {...props} style={{ ...style, backgroundColor: 'black', borderRadius: '4px' }} />
        }
      >
        <div className="container">
          <div
            style={{
              fontFamily: "Poppins",
              fontSize: 20,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              paddingTop: "2%",
              paddingBottom: "2%",
            }}
          >
            Top Gutschein
          </div>

          {loading ? (
            <div style={{ textAlign: "center", padding: "50px 0" }}>
              <img src='/voucher.gif' style={{ width: 100, height: 100 }} alt="Loading..." />
            </div>
          ) : (
            <div className="coupon-container">
              {coupons.length === 0 ? (
                <p style={{ textAlign: "center" }}>No coupons available.</p>
              ) : (
                coupons.map((coupon, index) => (
                  <div 
                    className="coupon-card" 
                    style={{ cursor: "pointer", transition: "transform 0.3s ease-in-out", marginBottom: '20px' }} 
                    key={coupon.Coupon_id} // Use Coupon_id as the key
                  >
                    <div className="image-wrapper">
                      <img src={coupon.CouponImage} alt={coupon.StoreName} className="coupon-image" />
                      <img src={coupon.StoreImage} alt={coupon.StoreImage} className="store-image" />
                    </div>
                    
                    <div
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 17,
                        fontWeight: "700",
                        paddingBottom: "10px",
                        textAlign: "center"
                      }}
                    >
                      {coupon.TitleCoupon}
                    </div>

                    {/* Delete Button */}
                    <div style={{ textAlign: 'center' }}>
                      <button
                        onClick={() => handleDeleteCoupon(coupon.Coupon_id)} // Pass Coupon_id here
                        style={{
                          backgroundColor: 'red',
                          color: 'white',
                          padding: '10px 20px',
                          border: 'none',
                          cursor: 'pointer',
                          borderRadius: '5px',
                        }}
                      >
                        Delete Coupon
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </Scrollbars>
    </div>
  );
}

export default DeleteTopCoupon;
