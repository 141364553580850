import React, { useEffect, useState } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { firebase } from '../../env';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation } from 'react-router-dom';
import { FaPercent, FaEuroSign } from "react-icons/fa";
import Select from 'react-select';
import { PiEmptyLight } from "react-icons/pi";
import axios from 'axios';
import { baseUrl } from '../../BaseUrl';

function AddCouponMore() {
  const location = useLocation();
  const store = location.state;
  // console.log(store.id)
  const [show, setShow] = useState(false);
  const [discounts, setDiscounts] = useState('');
  const [titleCoupon, setTitleCoupon] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [startDate, setStartDate] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [storeUrl, setStoreUrl] = useState('');
  const [discountHanding, setDiscountHanding] = useState('');
  const [couponDiscription, setCouponDiscription] = useState('');
  const [discountsIn, setDiscountsIn] = useState();
  const [confirm, setConfirm] = useState(false);
  const [AddTopOffer, setAddTopOffer] = useState(false);
  const [topOfferDetails, setTopOfferDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [editCouponId, setEditCouponId] = useState(null);
  const [loadingCoupons, setLoadingCoupons] = useState(true);
  const [storeLogo, setStoreLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [couponId,setCouponID]=useState();


  const options = [
    { value: 'percentage', label: <div><FaPercent /> Percentage</div> },
    { value: 'Euro', label: <div><FaEuroSign /> Euro</div> } ,
    { value: '', label: <div><PiEmptyLight /> Nothing</div> },
  ];

  const handleSelectChange = (selectedOption) => {
    setDiscountsIn(selectedOption.value);
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setStoreLogo(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setLogoPreview(null);
    }
  };
  const cleanModel = () => {
    setDiscounts('');
    setTitleCoupon('');
    setCouponCode('');
    setStoreLogo(null);
    setLogoPreview(null);
    setStartDate('');
    setExpireDate('');
    setStoreUrl('');
    setConfirm(false);
    setCouponDiscription('');
    setDiscountsIn();
    setDiscountHanding('');
    setAddTopOffer(false);
    setTopOfferDetails(''); // Reset top offer details
    setEditCouponId(null);
  };

  const fetchCoupons = async () => {
    setLoadingCoupons(true); // Set loading to true when fetching starts
    try {
      const response = await axios.get(`${baseUrl}api/coupons/store/${store.id}`);
      let result = response.data;
  
      // Preprocess timestamps if necessary
      result = result.map(coupon => {
        return {
          ...coupon,
          timestamp: new Date(coupon.timestamp)
        };
      });
  
      // Sort the coupons by time in descending order (newest first)
      const sortedCoupons = result.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
  
      // Log sorted coupons to verify sorting order
      console.log('Sorted coupons:', sortedCoupons);
  
      setCoupons(sortedCoupons);
    } catch (error) {
      console.log('Error fetching coupons:', error);
    } finally {
      setLoadingCoupons(false); // Set loading to false when fetching completes
    }
  };
  

  useEffect(() => {
    fetchCoupons();
  }, []);
console.log(coupons)
const handleSave = async () => {
  setLoading(true);
  if(couponId){
    try {
      const formData = new FormData();
      formData.append('StoreId', store.id);
      formData.append('TitleCoupon', titleCoupon);
      formData.append('CouponImage', storeLogo); // Image ko FormData mein include kar rahe hain
      formData.append('CouponCode', couponCode);
      formData.append('Discounts', discounts);
      formData.append('DiscountsIn', discountsIn);
      formData.append('StartDate', startDate);
      formData.append('ExpireDate', expireDate);
      formData.append('StoreURL', storeUrl);
      formData.append('DiscountsHeading', discountHanding);
      formData.append('CouponDescription', couponDiscription);
      formData.append('topOffer', AddTopOffer);
      formData.append('ConfirmOffer', topOfferDetails);

      await axios.put(`${baseUrl}api/coupons/${couponId}`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data', // Ensure ke ye header use ho raha hai
          },
      });

      cleanModel();
      setShow(false);
      setCouponID();
      fetchCoupons();
      toast.success('coupon Updata Successfully');
  } catch (error) {
      toast.error('Coupon is not update');
      console.error('Error saving coupon:', error);
  } finally {
      setLoading(false);
  }
    
  }else{
  try {
      const formData = new FormData();
      formData.append('StoreId', store.id);
      formData.append('TitleCoupon', titleCoupon);
      formData.append('CouponImage', storeLogo); // Image ko FormData mein include kar rahe hain
      formData.append('CouponCode', couponCode);
      formData.append('Discounts', discounts);
      formData.append('DiscountsIn', discountsIn);
      formData.append('StartDate', startDate);
      formData.append('ExpireDate', expireDate);
      formData.append('StoreURL', storeUrl);
      formData.append('DiscountsHeading', discountHanding);
      formData.append('CouponDescription', couponDiscription);
      formData.append('topOffer', AddTopOffer);
      formData.append('ConfirmOffer', topOfferDetails);

      await axios.post(`${baseUrl}api/createCoupon`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data', // Ensure ke ye header use ho raha hai
          },
      });

      cleanModel();
      setShow(false);
      fetchCoupons();
      toast.success('Coupon Created Successfully');
  } catch (error) {
      toast.error('Error Saving Coupon');
      console.error('Error saving coupon:', error);
  } finally {
      setLoading(false);
  }}
};


  const handleEdit = (coupon) => {
    // alert(JSON.stringify(coupon))
    // return
    setCouponID(coupon.id)
    setTitleCoupon(coupon.TitleCoupon);
    setStoreLogo(coupon.CouponImage)
    setDiscounts(coupon.Discounts);
    setCouponCode(coupon.CouponCode);
    setStartDate(coupon.StartDate);
    setExpireDate(coupon.ExpireDate);
    setStoreUrl(coupon.StoreURL);
    setConfirm(coupon.confirm);
    setDiscountHanding(coupon.DiscountsHeading)
    setCouponDiscription(coupon.CouponDescription);
    setDiscountsIn(coupon.DiscountsIn);
    setAddTopOffer(coupon.addTopOffer);
    setTopOfferDetails(coupon.ConfirmOffer); // Set top offer details
    setEditCouponId(coupon.id);
    setShow(true);
  };

  const handleDelete = async (couponId) => {
    
    if (window.confirm("Are you sure you want to delete this coupon?")) {
      try {
       await axios.delete(`${baseUrl}api/coupons/${couponId}`)
        toast.success('Coupon Deleted Successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchCoupons();
      } catch (error) {
        toast.error('Error Deleting Coupon', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(error);
      }
    }
  };

  return (
    <div style={{ height: '100vh', overflow: 'hidden', backgroundColor: "#f3f9f9", borderRadius: 10 }}>
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        autoHide
        autoHideTimeout={10}
        autoHideDuration={10}
        thumbMinSize={10}
        universal={true}
        renderThumbVertical={({ style, ...props }) =>
          <div {...props} style={{ ...style, backgroundColor: 'black', borderRadius: '4px' }} />
        }
      >
        <Row className="align-items-center" style={{ padding: "20px" }}>
          <Col xs={12} md={3}>
            <img src={`${store.StoreImage}`} alt={store.storeName} style={{ width: 200, height: 80 }} />
          </Col>
          <Col xs={12} md={6}>
            <p>Store Name: <strong>{store.storeName}</strong></p>
          </Col>
          <Col xs={12} md={3} className="d-flex justify-content-end">
            <Button type="button" className="btn btn-primary" onClick={() => setShow(true)}>
              {editCouponId ? 'Edit Coupon' : 'Add Coupon'}
            </Button>
          </Col>
        </Row>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          scrollable
          size="lg"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title" style={{ color: "green" }}>
              Getgutschein
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "justify" }}>
            <Form>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formTitleCoupon">
                    <Form.Label>Title Coupon</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter title coupon"
                      value={titleCoupon}
                      onChange={(e) => setTitleCoupon(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
          <Form.Group controlId="formImageUpload">
            <Form.Label>Upload Image</Form.Label>
            <Form.Control
             type="file"
             className="form-control"
             id="storeLogo"
             onChange={handleImageUpload}
            />
            {logoPreview && (
              <div className="mt-2">
                <img
                  src={logoPreview}
                  alt="Preview"
                  style={{ maxWidth: '100%', maxHeight: '150px' }}
                />
              </div>
            )}
          </Form.Group>
        </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formDiscounts">
                    <Form.Label>Discounts</Form.Label>
                    <Form.Control
                      type=""
                      placeholder="Enter discounts"
                      value={discounts}
                      onChange={(e) => setDiscounts(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formDiscountsIn">
                    <Form.Label>Discounts In</Form.Label>
                    <Select
                      options={options}
                      onChange={handleSelectChange}
                      value={options.find(option => option.value === discountsIn)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formStartDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formExpireDate">
                    <Form.Label>Expire Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={expireDate}
                      onChange={(e) => setExpireDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formStoreUrl">
                    <Form.Label>Store URL</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter store URL"
                      value={storeUrl}
                      onChange={(e) => setStoreUrl(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formStoreUrl">
                    <Form.Label>Discounts Heading</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter discout heading"
                      value={discountHanding}
                      onChange={(e) => setDiscountHanding(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
              <Col>
                  <Form.Group controlId="formCouponCode">
                    <Form.Label>Coupon Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter coupon code"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formCouponDescription">
                    <Form.Label>Coupon Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter coupon description"
                      value={couponDiscription}
                      onChange={(e) => setCouponDiscription(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formConfirmCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Confirm This is top Offer"
                      checked={AddTopOffer}
                      onChange={(e) => setAddTopOffer(e.target.checked)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {AddTopOffer && (
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formTopOfferDetails">
                      <Form.Label>Top Offer Details</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter top offer details"
                        value={topOfferDetails}
                        onChange={(e) => setTopOfferDetails(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { setShow(false); cleanModel(); }}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave} disabled={loading}>
              {loading ? 'Saving...' : 'Save Changes'}
            </Button>
          </Modal.Footer>
        </Modal>

        <ToastContainer />

        {loadingCoupons ? (
  <div>Loading...</div>
) : (
  <Row className="mb-3">
    {coupons.map((coupon, index) => (
      <Col key={index} sm={12} md={6} lg={4}>
        <Card style={{ margin: '10px' }}>
          <Card.Body>
            <Card.Title>{coupon.TitleCoupon}</Card.Title>
            <Card.Text>
              Discounts: {coupon.Discounts} {coupon.DiscountsIn}<br />
              Coupon Code: {coupon.CouponCode}<br />
              Start Date: {coupon.StartDate}<br />
              Expire Date: {coupon.ExpireDate}<br />
              Store URL: {coupon.StoreURL}<br />
              Discount Handling: {coupon.DiscountsHeading}<br />
              Description: {coupon.CouponDescription}<br />
              Top Offer: {coupon.addTopOffer ? 'No' : 'Yes'}<br />
              {coupon.ConfirmOffer && (
                <>
                  Top Offer Details: {coupon.ConfirmOffer}<br />
                </>
              )}
            </Card.Text>
            <Button variant="primary" onClick={() => handleEdit(coupon)}>Edit</Button>
            <Button variant="danger" onClick={() => handleDelete(coupon.id)}>Delete</Button>
          </Card.Body>
        </Card>
      </Col>
    ))}
  </Row>
)}

      </Scrollbars>
    </div>
  );
}

export default AddCouponMore;
