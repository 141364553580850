import React, { useState } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { FaBars } from "react-icons/fa";
import { AiOutlineShop } from "react-icons/ai";
import { BiSolidCoupon } from "react-icons/bi";
import { FcSalesPerformance } from "react-icons/fc";
import { FaShop } from "react-icons/fa6";
import { MdAutoDelete } from "react-icons/md";
import { FaSitemap } from "react-icons/fa";
import { MdAirlineStops } from "react-icons/md";
const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: 'create-shop',
      name: 'Create Shop',
      icon: <AiOutlineShop />
    },
    {
      path: 'add-coupon',
      name: 'Add Coupon',
      icon: <BiSolidCoupon />
    },
    {
      path: 'AddSale',
      name: 'Add Sale',
      icon: <FcSalesPerformance /> 
    },
    {
      path: 'SelectShop',
      name: 'Select store',
      icon: <FaShop  /> 
    },
    
    {
      path: 'ViewShop',
      name: 'View store',
      icon: <FaShop  /> 
    },
    {
      path: 'CreateCategories',
      name: 'Create Categories',
      icon: <FaSitemap  /> 
    },  {
      path: 'SelectStoreInCategories',
      name: 'Select Store In Categories',
      icon: <FaSitemap  /> 
    },{
      path: 'SelectTopCoupon',
      name: 'Select Top Coupon',
      icon: <MdAirlineStops   /> 
    },{
      path: 'DeleteTopCoupon',
      name: 'Delete Top Coupon',
      icon: <MdAutoDelete />
    },
    
  ];

  return (
    <div className="containerA">
      <div style={{ width: isOpen ? '200px' : '50px' }} className="sidebar">
        <div className="top_section">
          <h1 style={{ display: isOpen ? 'block' : 'none' }} className="logo"><img src='/adminlogo.png' style={{width:50,height:50}}/></h1>
          <div style={{ marginLeft: isOpen ? '50px' : '0px' }} className="bars">
            <FaBars onClick={toggle} />
          </div>
        </div>
        {menuItem.map((item, index) => (
          <NavLink to={item.path} key={index} className="link">
            <div className="icon">{item.icon}</div>
            <div style={{ display: isOpen ? 'block' : 'none' }} className="link_text">{item.name}</div>
          </NavLink>
        ))}
      </div>
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default Sidebar;
