import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  getFirestore,
  collection,
  addDoc,
  query,
  orderBy,
  getDocs,
  serverTimestamp,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';

function CreateCategories() {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // State for confirmation modal
  const [loading, setLoading] = useState(false);
  const [createCategories, setCreateCategories] = useState('');
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [stores, setStores] = useState([]);
  const [loadingStores, setLoadingStores] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStores, setSelectedStores] = useState([]);
  const [categoryToDelete, setCategoryToDelete] = useState(null); // Category to be deleted

  useEffect(() => {
    fetchCategories();
    fetchStores();
  }, []);

  const handleSave = async () => {
    setLoading(true);
    try {
      const firestore = getFirestore();
      const storeCollection = collection(firestore, 'Categories');
      await addDoc(storeCollection, {
        createCategories,
        createdAt: serverTimestamp(),
      });
      setCreateCategories('');
      toast.success('Category created successfully', {
        position: 'top-right',
        autoClose: 3000,
      });
      setShowCreateModal(false);
      fetchCategories();
    } catch (error) {
      toast.error('Failed to create category', {
        position: 'top-right',
        autoClose: 3000,
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const firestore = getFirestore();
      const categoriesCollection = collection(firestore, 'Categories');
      const categoriesQuery = query(categoriesCollection, orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(categoriesQuery);
      const categoriesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesList);
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoadingCategories(false);
    }
  };

  const fetchStores = async () => {
    setLoadingStores(true);
    try {
      const firestore = getFirestore();
      const storeCollection = collection(firestore, 'Store');
      const storeQuery = query(storeCollection, orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(storeQuery);
      const storesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStores(storesList);
    } catch (error) {
      console.error('Error fetching stores:', error);
    } finally {
      setLoadingStores(false);
    }
  };

  const fetchSelectedStores = async (categoryId) => {
    try {
      const firestore = getFirestore();
      const categoryDocRef = doc(firestore, 'Categories', categoryId);
      const categoryDoc = await getDoc(categoryDocRef);
      if (categoryDoc.exists()) {
        const categoryData = categoryDoc.data();
        const selectedStoresList = categoryData.stores || [];
        setSelectedStores(selectedStoresList);
      }
    } catch (error) {
      console.error('Error fetching selected stores:', error);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    fetchSelectedStores(category.id);
    setShowCategoryModal(true);
  };

  const handleStoreClick = (store) => {
    const isSelected = selectedStores.find((selectedStore) => selectedStore === store.id);
    
    if (!isSelected) {
      setSelectedStores([...selectedStores, store.id]);
    }
  };

  const handleRemoveStore = (storeId) => {
    setSelectedStores(selectedStores.filter((id) => id !== storeId));
  };

  const handleSaveSelectedStores = async () => {
    setLoading(true);
    try {
      const firestore = getFirestore();
      const categoryDocRef = doc(firestore, 'Categories', selectedCategory.id);
      const categoryDoc = await getDoc(categoryDocRef);
      const categoryData = categoryDoc.data();
      const existingStores = categoryData.stores || [];
      
      const newStores = selectedStores.filter(storeId => !existingStores.includes(storeId));
      const duplicateStores = selectedStores.filter(storeId => existingStores.includes(storeId));

      await updateDoc(categoryDocRef, {
        stores: [...existingStores, ...newStores],
      });

      const storeIdToNameMap = stores.reduce((map, store) => {
        map[store.id] = store.storeName;
        return map;
      }, {});

      if (newStores.length > 0) {
        toast.success('Stores saved successfully', {
          position: 'top-right',
          autoClose: 3000,
        });
      }
      
      if (duplicateStores.length > 0) {
        toast.info(`Some stores were already selected: ${duplicateStores.map(id => storeIdToNameMap[id]).join(', ')}`, {
          position: 'top-right',
          autoClose: 3000,
        });
      }

      setShowCategoryModal(false);
    } catch (error) {
      toast.error('Failed to save stores', {
        position: 'top-right',
        autoClose: 3000,
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCategory = async () => {
    setShowCategoryModal(false)
    setLoading(true);
    try {
      const firestore = getFirestore();
      const categoryDocRef = doc(firestore, 'Categories', categoryToDelete);
      await deleteDoc(categoryDocRef);
      
      toast.success('Category deleted successfully', {
        position: 'top-right',
        autoClose: 3000,
      });

      // Update the UI by fetching categories again
      fetchCategories();
      setShowConfirmModal(false); // Close confirmation modal
    } catch (error) {
      toast.error('Failed to delete category', {
        position: 'top-right',
        autoClose: 3000,
      });
      console.error('Error deleting category:', error);
    } finally {
      setLoading(false);
    }
  };

  const openConfirmModal = (categoryId) => {
    setCategoryToDelete(categoryId); // Set the category to be deleted

    setShowConfirmModal(true); // Show the confirmation modal
  };

  const filteredStores = stores.filter((store) =>
    store.storeName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ height: '100vh', overflow: 'hidden', backgroundColor: '#f3f9f9', borderRadius: 10 }}>
      <Scrollbars autoHide>
        <div className="container mt-5">
          <Button variant="primary" onClick={() => setShowCreateModal(true)}>
            Create New Category
          </Button>
          <ToastContainer />
          <Modal
            show={showCreateModal}
            onHide={() => setShowCreateModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Create New Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formCategoryName">
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter category name"
                    value={createCategories}
                    onChange={(e) => setCreateCategories(e.target.value)}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  onClick={handleSave}
                  disabled={loading}
                  className="mt-3"
                >
                  {loading ? 'Saving...' : 'Save'}
                </Button>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal
            show={showCategoryModal}
            onHide={() => setShowCategoryModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Category: {selectedCategory?.createCategories}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Stores</h5>
              <Form.Control
                type="text"
                placeholder="Search stores..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="mb-3"
              />
              {loadingStores ? (
                <p>Loading stores...</p>
              ) : (
                <div className="d-flex flex-wrap">
                  {filteredStores.map((store) => (
                    <div
                      key={store.id}
                      className={`card m-2 ${selectedStores.includes(store.id) ? 'border-primary' : ''}`}
                      style={{ width: '18rem', cursor: 'pointer' }}
                      onClick={() => handleStoreClick(store)}
                    >
                      <div className="card-body">
                        <h5 className="card-title">{store.storeName}</h5>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <Button
                variant="primary"
                onClick={handleSaveSelectedStores}
                disabled={loading}
                className="mt-3"
              >
                {loading ? 'Saving...' : 'Save Stores'}
              </Button>
            </Modal.Body>
          </Modal>

          <Modal
            show={showConfirmModal}
            onHide={() => setShowConfirmModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete this category?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleDeleteCategory}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="d-flex flex-wrap justify-content-center">
            {loadingCategories ? (
              <p>Loading...</p>
            ) : (
              categories.map((category) => (
                <div
                  key={category.id}
                  className="card m-3"
                  style={{ width: '18rem', cursor: 'pointer' }}
                  onClick={() => handleCategoryClick(category)}
                >
                  <div className="card-body">
                    <h5 className="card-title">{category.createCategories}</h5>
                    <Button
                      variant="danger"
                      onClick={() => openConfirmModal(category.id)} // Open confirmation modal
                      className="mt-2"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

export default CreateCategories;
